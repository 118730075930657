import { CheckCircle } from '@phosphor-icons/react'
import Link from 'next/link'
import MobileRenderImageDouble from '@/src/blocks/MyBlinkBenefits/mobile_render_double.png'
import MobileRenderImageSingle from '@/src/blocks/MyBlinkBenefits/mobile_render_single.png'
import Picture from '@/src/components/elements/Picture'
import Container from '@/src/components/layout/Container'

export default function Component() {
  return (
    <Container fullPageOnMobile>
      <div className="md:hidden">
        <Mobile />
      </div>
      <div className="hidden md:block">
        <Desktop />
      </div>
    </Container>
  )
}

function Mobile() {
  return (
    <div className="bg-blue-100 px-5 py-12">
      <h2 className="text-4xl font-extrabold">Deine myBLINK Vorteile</h2>
      <p className="mt-3 text-gray-800">
        myBLINK vereinfacht dein Weg zum Führerschein! Alles an einem Ort
      </p>
      <div className="mt-4 space-y-3 text-sm text-gray-800">
        <div className="flex space-x-2">
          <CheckCircle
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-600"
          />
          <p>Alle Kurse und Fahrlektionen online buchen</p>
        </div>
        <div className="flex space-x-2">
          <CheckCircle
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-600"
          />
          <p>Fortschritte transparent einsehen</p>
        </div>
        <div className="flex space-x-2">
          <CheckCircle
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-600"
          />
          <p>Termine und Kosten immer im Überblick</p>
        </div>
        <div className="flex space-x-2">
          <CheckCircle
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-600"
          />
          <p>Lernmaterial für private Übungsfahrten</p>
        </div>
        <div className="flex space-x-2">
          <CheckCircle
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-600"
          />
          <p>Online Gesuchsformular Service</p>
        </div>
      </div>
      <Link
        href="https://blinkdrive.ch/myblink/"
        className="mt-6 inline-block font-bold text-purple-600 hover:text-purple-800"
      >
        Alle Vorteile ansehen
      </Link>
    </div>
  )
}

function Desktop() {
  return (
    <Link href="https://blinkdrive.ch/myblink/" className="pt-28">
      <div className="group relative grid grid-cols-5 rounded-xl bg-purple-800 text-white">
        <div className="col-span-3 px-16 py-14 xl:col-span-2">
          <h2 className="text-7xl font-extrabold">Deine myBLINK Vorteile</h2>
          <p className="mt-3 text-lg text-purple-50">
            myBLINK vereinfacht dein Weg zum Führerschein! Alles an einem Ort
          </p>
          <div className="mt-4 space-y-3 text-purple-100">
            <div className="flex space-x-2">
              <CheckCircle
                size={20}
                weight="fill"
                className="mt-0.5 shrink-0 text-purple-200"
              />
              <p>Alle Kurse und Fahrlektionen online buchen</p>
            </div>
            <div className="flex space-x-2">
              <CheckCircle
                size={20}
                weight="fill"
                className="mt-0.5 shrink-0 text-purple-200"
              />
              <p>Fortschritte transparent einsehen</p>
            </div>
            <div className="flex space-x-2">
              <CheckCircle
                size={20}
                weight="fill"
                className="mt-0.5 shrink-0 text-purple-200"
              />
              <p>Termine und Kosten immer im Überblick</p>
            </div>
            <div className="flex space-x-2">
              <CheckCircle
                size={20}
                weight="fill"
                className="mt-0.5 shrink-0 text-purple-200"
              />
              <p>Lernmaterial für private Übungsfahrten</p>
            </div>
            <div className="flex space-x-2">
              <CheckCircle
                size={20}
                weight="fill"
                className="mt-0.5 shrink-0 text-purple-200"
              />
              <p>Online Gesuchsformular Service</p>
            </div>
          </div>
          <div className="mt-6 inline-block font-bold">
            Alle Vorteile ansehen
          </div>
        </div>
        <div className="relative col-span-2 -mt-28 origin-bottom transform-gpu transition-transform group-hover:scale-[101%] xl:col-span-3">
          <Picture
            permalink={MobileRenderImageSingle}
            alt="myBLINK Vorteile"
            fill
            sizes="50vw"
            className="object-contain object-bottom xl:hidden"
          />
          <Picture
            permalink={MobileRenderImageDouble}
            alt="myBLINK Vorteile"
            fill
            sizes="50vw"
            className="hidden object-contain object-bottom xl:block"
          />
        </div>
      </div>
    </Link>
  )
}
