import {
  Clock,
  Info,
  MapPin,
  MapTrifold,
  ShareNetwork,
  X,
} from '@phosphor-icons/react'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, SyntheticEvent, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Control, SubmitErrorHandler, UnpackNestedValue } from 'react-hook-form'
import useSWR from 'swr'
import UncontrolledVoucherInput from '../form/UncontrolledVoucherInput'
import { Voucher } from '../form/VoucherInput'
import LoadingSpinner from '../icons/LoadingSpinner'
import SaleIcon from '../icons/SaleIcon'
import { ClickTooltip } from './Tooltip'
import { DigitalFirstAidLanguage } from '@/src/@types/admin'
import { get, getCourses } from '@/src/BlinkAdminApiClient'
import { DateInput } from '@/src/components/core/DateInput'
import { Input } from '@/src/components/core/Input'
import { Select } from '@/src/components/core/Select'
import ArrowAction from '@/src/components/elements/ArrowAction'
import DigitalFirstAidImage from '@/src/components/elements/course/digitalFirstAid.png'
import LicenseDialog from '@/src/components/elements/course/LicenseDialog'
import Button from '@/src/components/form/Button'
import Checkbox from '@/src/components/form/Checkbox'
import ErrorMessage from '@/src/components/form/ErrorMessage'
import { LaravelFormRegister } from '@/src/hooks/useLaravelForm'
import share from '@/src/lib/share'
import { colorStartingWithB } from '@/src/lib/validation'

export type RegistrationForm = {
  first_name: string
  last_name: string
  email: string
  phone: string
  street: string
  city: string
  postal_code: string
  date_of_birth: string
  voucher: string
  color: string
  accepts_conditions: boolean
  driver_license: string
  language: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

type OnInputType<
  Field extends keyof RegistrationForm = keyof RegistrationForm,
> = (input: Field, value: RegistrationForm[Field]) => void

export default function Course({
  course,
  open,
  register = () => ({ error: undefined }),
  control,
  onSubmit = () => () => {},
  onInput = () => {},
  reset = () => {},
}: {
  course: Awaited<ReturnType<typeof getCourses>>[0]
  open: boolean
  register?: LaravelFormRegister<RegistrationForm> | (() => { error?: string })
  control?: Control<RegistrationForm, any>
  onSubmit?: (
    success: (response: AxiosResponse) => void,
    prepareData?: (
      values: UnpackNestedValue<any>
    ) => (any & UnpackNestedValue<any>) | FormData,
    error?: SubmitErrorHandler<any>
  ) => (e: SyntheticEvent<HTMLFormElement>) => void
  onInput?: OnInputType
  reset?: () => void
}) {
  const router = useRouter()
  const postalCodeRegister = register('postal_code')
  const cityRegister = register('city')

  const [success, setSuccess] = useState(false)
  const [sending, setSending] = useState(false)

  const [voucher, setVoucher] = useState<Voucher | undefined>(undefined)

  const [showIDNrExplanation, setShowIDNrExplanation] = useState(false)

  const [userSelectedCanton, setUserSelectedCanton] = useState(
    course.provisionalDrivingLicenseDescription
  )

  const { data: languages } = useSWR(
    course.type === 'eNothelfer' && 'languages',
    async () => await get<DigitalFirstAidLanguage[]>('/website/v2/languages')
  )

  const openForm = () => {
    // Send course open event
    if (course.tracking && course.tracking.open) {
      TagManager.dataLayer({
        dataLayer: course.tracking.open,
      })
    }

    router.replace(
      {
        pathname: router.asPath.split(/[?#]/)[0],
        query: { course: course.slug },
      },
      undefined,
      { shallow: true }
    )
  }

  const closeForm = () => {
    const { course: _, ...query } = router.query
    router.query = query
    router.replace(
      {
        pathname: router.asPath.split(/[?#]/)[0],
      },
      undefined,
      { shallow: true }
    )
  }

  const syncInput = (name: keyof RegistrationForm) => {
    return (event: SyntheticEvent<HTMLInputElement>) => {
      onInput(name, event.currentTarget.value)
    }
  }

  const registrationUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/courses/${course.slug}/register`

  return (
    <>
      {course.needsLicense && (
        <LicenseDialog
          show={showIDNrExplanation}
          close={() => setShowIDNrExplanation(false)}
          userSelectedCanton={userSelectedCanton}
          setUserSelectedCanton={setUserSelectedCanton}
        />
      )}
      <div className="@container">
        <div
          className={classNames([
            'rounded-lg bg-blue-50 px-5 @3xl:px-6 py-5',
            {
              'opacity-70': course.isFullyBooked,
            },
          ])}
        >
          <div className="flex h-8 items-center justify-between">
            <div className="flex items-center">
              <p
                className={classNames([
                  'px-2 py-1 rounded text-xs font-bold',
                  {
                    'bg-pink-600 text-white':
                      course.type === 'eNothelfer' && !course.isFullyBooked,
                    'bg-blue-150':
                      course.type !== 'eNothelfer' && !course.isFullyBooked,
                    'bg-gray-700 text-white': course.isFullyBooked,
                  },
                ])}
              >
                {getTagText(course)}
              </p>
              {course.isFullyBooked && (
                <p className="ml-3 text-xs font-bold text-gray-800">
                  Ausgebucht
                </p>
              )}
              {(course.seats === 2 ||
                course.seats === 1 ||
                (course.seats === 0 && course.jokerSeats > 0)) && (
                <p className="ml-3 text-xs font-bold text-yellow-700">
                  {getSeatText(course)}
                </p>
              )}
            </div>
            {!open &&
              course.communicatedOriginalPrice &&
              !course.isFullyBooked && (
                <SaleIcon className="size-[40px] text-pink-400" />
              )}
            {open && !success && (
              <button type="button" onClick={closeForm}>
                <X size={32} alt="Schliessen" />
              </button>
            )}
          </div>
          <div className="mt-5 flex flex-col space-y-6 @3xl:flex-row @3xl:justify-between @3xl:space-y-0">
            <div>
              <div
                className={classNames('space-y-2', {
                  '@3xl:grid @3xl:gap-y-3 @3xl:space-y-0':
                    course.timeslots.length > 1,
                })}
                style={{
                  gridTemplateColumns: 'auto 1fr',
                }}
              >
                {course.timeslots.map((timeslot) => (
                  <TimeslotDay
                    key={timeslot.isoDay}
                    timeslot={timeslot}
                    compact={course.timeslots.length !== 1}
                    mapLink={course.map}
                    address={course.location.address}
                  />
                ))}
              </div>
              {course.timeslots.length !== 1 && (
                <Link
                  href={course.map}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-3 flex space-x-1 @3xl:mt-3"
                >
                  <MapPin
                    size={20}
                    weight="fill"
                    className="mt-0.5 shrink-0 opacity-60"
                  />
                  <span>{course.location.address}</span>
                </Link>
              )}
              {course.type === 'eNothelfer' && !open && (
                <div className="mt-5 flex space-x-1">
                  <Info
                    size={20}
                    weight="fill"
                    className="mt-0.5 shrink-0 opacity-60"
                  />
                  <p>
                    Mit dem BLINK{' '}
                    <Link
                      href="/enothelfer"
                      target="_blank"
                      className="underline"
                    >
                      eLearning
                    </Link>{' '}
                    machst du den Nothilfekurs in{' '}
                    {course.timeslots.length === 1
                      ? 'nur einem Tag'
                      : 'nur 7 Stunden!'}
                  </p>
                </div>
              )}
            </div>
            <div className="flex items-end justify-between @3xl:ml-6 @3xl:flex-col">
              <div>
                <div className="items-center @3xl:flex @3xl:justify-end">
                  {course.communicatedOriginalPrice && (
                    <p className="text-sm line-through @3xl:mr-2">
                      {course.communicatedOriginalPrice} CHF
                    </p>
                  )}
                  {!course.communicatedOriginalPrice && voucher && (
                    <p className="text-sm line-through @3xl:mr-2">
                      {course.price} CHF
                    </p>
                  )}
                  <p className="text-lg font-extrabold">
                    {voucher ? voucher.new_price : course.price} CHF
                  </p>
                </div>
                <p className="text-xs opacity-60 @3xl:text-end">
                  {course.priceDescription}
                </p>
              </div>
              {!open && !course.isFullyBooked && (
                <Button onClick={openForm} className="w-[140px] @3xl:w-[160px]">
                  Anmelden
                </Button>
              )}
            </div>
          </div>
          {open && (
            <div className="mt-5 flex flex-col items-start space-y-3 @3xl:flex-row @3xl:space-x-4 @3xl:space-y-0">
              <ClickTooltip
                text="Der Kurslink wurde in deine Zwischenablage kopiert"
                className="inline-flex"
              >
                <button
                  type="button"
                  onClick={() =>
                    share(
                      router.asPath,
                      { course: course.slug },
                      `${course.type} ${
                        course.location.name.split(' ')[
                          course.location.name.split(' ').length - 1
                        ]
                      } | Kursdaten & Anmeldung`,
                      course.timeslots
                        .map((timeslot) => timeslot.day)
                        .join(' & ')
                    )
                  }
                  className="flex shrink-0 space-x-2 text-sm font-bold text-purple-600 hover:text-purple-800"
                >
                  <ShareNetwork size={20} weight="fill" />
                  <p>Teilen</p>
                </button>
              </ClickTooltip>
              <Link
                href={course.map}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex shrink-0 space-x-2 text-sm font-bold text-purple-600 hover:text-purple-800"
              >
                <MapTrifold size={20} weight="fill" className="shrink-0" />
                <span>Auf Google Maps anzeigen</span>
              </Link>
            </div>
          )}
          {open && !success && (
            <div className="mt-8 @3xl:mt-6">
              {course.type === 'eNothelfer' && (
                <div className="-mx-5 mb-4 flex overflow-hidden bg-blue-125 p-5 @3xl:mx-0 @3xl:rounded-lg @3xl:p-0">
                  <div className="@3xl:p-8 @3xl:pr-4">
                    <p className="font-bold">
                      Nothelferkurs mit eLearning: So funktionierts!
                    </p>
                    <p className="mt-2 @3xl:mt-3">
                      <span className="font-bold">
                        Teil 1: eLearning (ca. 3 Stunden)
                      </span>
                      <br />
                      Den ersten Kursteil machst du selbstständig im eLearning.
                      <br /> <br />
                      <span className="font-bold">
                        Teil 2: Praktischer Kurs (ca. 7 Stunden)
                      </span>
                      <br />
                      Für den praktischen Teil des Kurses kommst du zu uns ins
                      Kurslokal. Die Kurssprache ist Deutsch / Schweizerdeutsch.
                      <br /> <br />
                      Damit du für den praktischen Kursteil zugelassen bist,
                      musst du vorab das eLearning zu 100% abgeschlossen haben.
                    </p>
                    <div className="mt-4">
                      <ArrowAction
                        href="/enothelfer"
                        target="_blank"
                        size="small"
                      >
                        Mehr erfahren
                      </ArrowAction>
                    </div>
                  </div>
                  <Image
                    src={DigitalFirstAidImage}
                    alt="eNothelfer"
                    className="hidden max-w-[330px] object-contain object-left-top @3xl:block lg:hidden xl:block"
                    unoptimized
                  />
                </div>
              )}
              <p className="text-lg font-extrabold">Kontakt</p>
              <form
                onSubmit={onSubmit(
                  () => {
                    if (course.tracking && course.tracking.registration) {
                      TagManager.dataLayer({
                        dataLayer: course.tracking.registration,
                      })
                    }
                    setSuccess(true)
                    reset()
                    setSending(false)
                  },
                  (data) => {
                    setSending(true)

                    return data
                  },
                  () => {
                    setSending(false)
                  }
                )}
                method="POST"
                action={registrationUrl}
              >
                <div className="mt-4 grid gap-2 @3xl:grid-cols-2">
                  <Input
                    label="Vorname"
                    kind="firstName"
                    color="white"
                    autoFocus={course.type !== 'eNothelfer'}
                    onInput={syncInput('first_name')}
                    {...register('first_name')}
                  />
                  <Input
                    label="Name"
                    kind="lastName"
                    color="white"
                    onInput={syncInput('last_name')}
                    {...register('last_name')}
                  />
                  <Input
                    label="Strasse + Nr."
                    kind="street"
                    color="white"
                    onInput={syncInput('street')}
                    {...register('street')}
                  />
                  <div>
                    <div className="space-y-2 @md:flex @md:space-x-2 @md:space-y-0">
                      <div className="@md:w-[120px]">
                        <Input
                          label="PLZ"
                          color="white"
                          onInput={syncInput('postal_code')}
                          hideErrorMessage={true}
                          {...postalCodeRegister}
                        />
                      </div>
                      <div className="grow">
                        <Input
                          label="Ort"
                          color="white"
                          onInput={syncInput('city')}
                          hideErrorMessage={true}
                          {...cityRegister}
                        />
                      </div>
                    </div>
                    {(cityRegister.error || postalCodeRegister.error) && (
                      <div>
                        <ErrorMessage error={cityRegister.error} />
                        <ErrorMessage error={postalCodeRegister.error} />
                      </div>
                    )}
                  </div>
                  <Input
                    label="E-Mail"
                    kind="email"
                    color="white"
                    onInput={syncInput('email')}
                    {...register('email')}
                  />
                  <Input
                    label="Handynummer Teilnehmer:in"
                    kind="tel"
                    color="white"
                    onInput={syncInput('phone')}
                    {...register('phone')}
                  />
                  <DateInput
                    label="Geburtsdatum (TT.MM.JJJJ)"
                    color="white"
                    {...register('date_of_birth')}
                    onInput={syncInput('date_of_birth')}
                  />
                  {course.needsLicense && (
                    <div className="relative">
                      <Input
                        color="white"
                        label={
                          userSelectedCanton?.labelName ||
                          'Lernfahrausweisnummer'
                        }
                        {...register('driver_license')}
                      />
                      <button
                        type="button"
                        onClick={() => setShowIDNrExplanation(true)}
                        className="absolute inset-y-0 right-3"
                      >
                        <Info
                          weight="fill"
                          size={24}
                          className="text-purple-700"
                        />
                      </button>
                    </div>
                  )}
                  {(course.type !== 'Nothelfer' &&
                    course.type !== 'eNothelfer' && (
                      <UncontrolledVoucherInput
                        control={control}
                        name="voucher"
                        label="Gutscheincode (optional)"
                        voucherChange={setVoucher}
                        voucherType="course"
                        courseId={course.slug}
                      />
                    )) || (
                    <Input
                      label="Gutscheincode (optional)"
                      color="white"
                      {...register('voucher')}
                    />
                  )}
                  {course.type === 'eNothelfer' && languages && (
                    <Select
                      label="Bevorzugte Sprache (für das eLearning)"
                      color="white"
                      {...register('language')}
                    >
                      {languages.map((language) => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </div>
                {course.jokerSeats > 0 && course.seats === 0 && (
                  <div className="mt-5">
                    <div className="flex items-center">
                      <span
                        className="size-5 shrink-0 rounded-full bg-yellow-700"
                        aria-hidden="true"
                      />
                      <h4 className="ml-2 font-bold">
                        Nur noch ein Springerplatz frei!
                      </h4>
                    </div>
                    <div className="mt-4 text-sm">
                      <p>
                        Dieser Kurs ist schon ausgebucht. Du kannst dich aber
                        für den Springerplatz anmelden.
                        <br />
                        <br />
                        Als Springer:in erscheinst du ganz normal zum Kursstart.
                        Wenn eine angemeldete Person nicht kommt, kannst du
                        ihren Platz einnehmen (in ca. 4 von 5 Fällen kannst du
                        bleiben). Wenn alle am Kurs teilnehmen, dann kannst du
                        leider nicht mitmachen. In diesem Fall erhältst du als
                        Entschädigung einen 50 CHF Gutschein und einen sicheren
                        Platz in einem anderen beliebigen Kurs.
                        <br />
                        <br />
                        Alle Infos dazu findest du in unseren{' '}
                        <Link
                          href="/allgemeine-geschaeftsbedingungen"
                          className="underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          allgemeinen Geschäftsbedingungen
                        </Link>
                        .
                        <br />
                        Nenn uns eine Farbe, die mit «B» beginnt:
                      </p>
                    </div>
                    <div className="mt-3">
                      <Input
                        label="Farbe"
                        color="white"
                        {...register('color', {
                          validate: colorStartingWithB,
                        })}
                      />
                    </div>
                  </div>
                )}
                <div className="mt-6">
                  <Checkbox
                    multiLine
                    required
                    {...register('accepts_conditions')}
                  >
                    Ich akzeptiere die{' '}
                    <Link
                      href="/allgemeine-geschaeftsbedingungen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      allgemeinen Geschäftsbedingungen
                    </Link>
                    . Die{' '}
                    <Link
                      href="/datenschutz"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      Datenschutzerklärung
                    </Link>{' '}
                    habe ich gelesen und verstanden.
                  </Checkbox>
                </div>
                <Button
                  type="submit"
                  status={sending ? 'active' : 'default'}
                  disabled={sending}
                  className="mt-8 inline-flex w-full items-center justify-center @3xl:w-auto"
                >
                  {sending && <LoadingSpinner className="mr-1 size-4" />}
                  Verbindlich anmelden
                </Button>
              </form>
            </div>
          )}
          {open && success && (
            <div className="mt-6">
              <h3 className="text-lg font-extrabold">
                Es hat geklappt! Vielen Dank für deine Anmeldung
              </h3>
              <p className="mt-3">
                Gleich gibt es von uns noch eine Bestätigung per E-Mail und SMS.
                Falls nichts ankommt, schau bitte kurz im Spamordner nach. Und
                wenn da auch nichts ist, dann melde dich bitte bei uns.
              </p>
              {course.type === 'eNothelfer' && (
                <p className="mt-2">
                  Voller Tatendrang? Wenn du willst, kannst du jetzt gleich mit
                  dem eNothelfer starten!
                </p>
              )}
              <div className="mt-8 flex flex-col space-y-4 @3xl:mt-6 @3xl:flex-row @3xl:space-x-6 @3xl:space-y-0">
                {course.type === 'eNothelfer' && (
                  <Button
                    as={Link}
                    target="_blank"
                    href="https://myblink.blinkdrive.ch/enothelfer"
                  >
                    eLearning starten
                  </Button>
                )}
                <Button
                  style="border"
                  onClick={() => {
                    setSuccess(false)
                  }}
                >
                  Weitere Person anmelden
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function getTagText(course: Parameters<typeof Course>[0]['course']) {
  const dayCount = course.timeslots.length
  switch (course.type) {
    case 'eNothelfer':
      return `${dayCount} Tag${dayCount === 1 ? '' : 'e'} (mit eLearning)`
    case 'Nothelfer':
      return `${dayCount} Tag${dayCount === 1 ? '' : 'e'}`
    default:
      return course.type
  }
}

function getSeatText(course: Parameters<typeof Course>[0]['course']) {
  if (course.seats === 2) {
    return '2 Plätze frei'
  }

  if (course.seats === 1) {
    return '1 Platz frei'
  }

  if (course.jokerSeats && course.seats === 0) {
    return 'Springerplatz'
  }
}

function TimeslotDay({
  timeslot,
  compact,
  mapLink,
  address,
}: {
  timeslot: Parameters<typeof Course>[0]['course']['timeslots'][0]
  compact: boolean
  mapLink: string
  address: string
}) {
  const parts = timeslot.slots.reduce(
    (
      parts: {
        start: string
        end: string
      }[],
      slot,
      index
    ) => {
      const slotCopy = Object.assign({}, slot)
      if (index === 0) {
        return [slotCopy]
      }

      if (slot.start === parts[parts.length - 1].end) {
        parts[parts.length - 1].end = slot.end
        return parts
      }

      return [...parts, slotCopy]
    },
    []
  )

  return (
    <div
      className={classNames({
        'flex flex-col space-y-2 @3xl:contents @3xl:space-y-0': compact,
        'space-y-2': !compact,
      })}
    >
      <p className="text-lg font-extrabold">
        <time dateTime={timeslot.isoDay}>{timeslot.day}</time>
      </p>
      <div
        className={classNames(
          'flex flex-col space-y-2 @3xl:flex-row @3xl:space-x-4 @3xl:space-y-0',
          {
            '@3xl:ml-4': compact,
          }
        )}
      >
        <div className="flex space-x-1">
          <Clock
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 opacity-60"
          />
          <p>
            {parts.map((slot, index) => (
              <Fragment key={slot.start}>
                <time dateTime={slot.start}>{slot.start}</time> –{' '}
                <time dateTime={slot.end}>{slot.end}</time>
                {index !== parts.length - 1 && <> & </>}
              </Fragment>
            ))}{' '}
            Uhr
          </p>
        </div>
        {!compact && (
          <Link
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex space-x-1"
          >
            <MapPin
              size={20}
              weight="fill"
              className="mt-0.5 shrink-0 opacity-60"
            />
            <span>{address}</span>
          </Link>
        )}
      </div>
    </div>
  )
}
