import { GetStaticPropsContext } from 'next'
import { FunctionComponent } from 'react'
import { PageParams, PreviewData } from '../@types/infrastructur'
import * as BookTrialLesson from '@/blocks/BookTrialLesson/Component'
import * as BookTrialLessonButton from '@/blocks/BookTrialLessonButton/Component'
import * as Box from '@/blocks/Box/Component'
import * as Catcher from '@/blocks/Catcher/Component'
import * as ContactForm from '@/blocks/ContactForm/Component'
import * as ContactWhatsapp from '@/blocks/ContactWhatsapp/Component'
import * as Content from '@/blocks/Content/Component'
import * as ContentFacts from '@/blocks/ContentFacts/Component'
import * as Courses from '@/blocks/Courses/Component'
import * as DefaultBlock from '@/blocks/DefaultBlock/Component'
import * as Faq from '@/blocks/Faq/Component'
import * as FreeTrialLessonHero from '@/blocks/FreeTrialLessonHero/Component'
import * as Gallery from '@/blocks/Gallery/Component'
import * as Guide from '@/blocks/Guide/Component'
import * as HeroBlob from '@/blocks/HeroBlob/Component'
import * as Icons from '@/blocks/Icons/Component'
import * as InteractiveMap from '@/blocks/InteractiveMap/Component'
import * as Jobs from '@/blocks/Jobs/Component'
import * as Lottery from '@/blocks/Lottery/Component'
import * as Magazine from '@/blocks/Magazine/Component'
import * as MagazineHero from '@/blocks/MagazineHero/Component'
import * as MeetupMap from '@/blocks/MeetupMap/Component'
import * as Mobiliar from '@/blocks/Mobiliar/Component'
import * as MyBlinkBenefits from '@/blocks/MyBlinkBenefits/Component'
import * as Offers from '@/blocks/Offers/Component'
import * as OfferTiles from '@/blocks/OfferTiles/Component'
import * as Packets from '@/blocks/Packets/Component'
import * as PartnersHero from '@/blocks/PartnersHero/Component'
import * as Prices from '@/blocks/Prices/Component'
import * as QuickLinks from '@/blocks/QuickLinks/Component'
import * as ShortLinks from '@/blocks/ShortLinks/Component'
import * as SliderHero from '@/blocks/SliderHero/Component'
import * as Story from '@/blocks/Story/Component'
import * as TcsForm from '@/blocks/TcsForm/Component'
import * as TcsWabCourses from '@/blocks/TcsWabCourses/Component'
import * as Team from '@/blocks/Team/Component'
import * as TeamFilter from '@/blocks/TeamFilter/Component'
import * as TeamSelected from '@/blocks/TeamSelected/Component'
import * as TeamSlider from '@/blocks/TeamSlider/Component'
import * as Testimonials from '@/blocks/Testimonials/Component'
import * as TestimonialsSlider from '@/blocks/TestimonialsSlider/Component'
import * as Tiles from '@/blocks/Tiles/Component'
import * as TrialLesson from '@/blocks/TrialLesson/Component'
import * as UpcomingFirstAidCourses from '@/blocks/UpcomingFirstAidCourses/Component'
import * as Video from '@/blocks/Video/Component'
import * as VideoHero from '@/blocks/VideoHero/Component'
import * as VideoSlider from '@/blocks/VideoSlider/Component'
import * as VoucherForm from '@/blocks/VoucherForm/Component'
import { ImageType } from '@/src/@types/statamic'

const blocks: Record<
  string,
  {
    getStaticProps?: (
      props: any,
      context: GetStaticPropsContext<PageParams, PreviewData>
    ) => any
    getTitle?: (props: any) => string | undefined
    getDescription?: (props: any) => string | undefined
    getImage?: (props: any) => ImageType | undefined
    default: FunctionComponent<any>
  }
> = {
  BookTrialLesson: BookTrialLesson,
  BookTrialLessonButton: BookTrialLessonButton,
  Box: Box,
  Catcher: Catcher,
  ContactForm: ContactForm,
  ContactWhatsapp: ContactWhatsapp,
  Content: Content,
  DefaultBlock: DefaultBlock,
  Gallery: Gallery,
  Guide: Guide,
  HeroBlob: HeroBlob,
  InteractiveMap: InteractiveMap,
  Jobs: Jobs,
  Magazine: Magazine,
  MagazineHero: MagazineHero,
  MeetupMap: MeetupMap,
  Packets: Packets,
  PartnersHero: PartnersHero,
  QuickLinks: QuickLinks,
  ShortLinks: ShortLinks,
  SliderHero: SliderHero,
  Story: Story,
  Testimonials: Testimonials,
  Tiles: Tiles,
  TrialLesson: TrialLesson,
  Team: Team,
  TeamFilter: TeamFilter,
  TeamSelected: TeamSelected,
  TeamSlider: TeamSlider,
  UpcomingFirstAidCourses: UpcomingFirstAidCourses,
  Video: Video,
  VideoHero: VideoHero,
  Courses: Courses,
  VoucherForm: VoucherForm,
  TestimonialsSlider: TestimonialsSlider,
  ContentFacts: ContentFacts,
  Icons: Icons,
  Mobiliar: Mobiliar,
  TcsForm: TcsForm,
  TcsWabCourses: TcsWabCourses,
  Faq: Faq,
  FreeTrialLessonHero: FreeTrialLessonHero,
  Prices: Prices,
  VideoSlider: VideoSlider,
  Lottery: Lottery,
  Offers: Offers,
  MyBlinkBenefits: MyBlinkBenefits,
  OfferTiles: OfferTiles,
}

export default blocks
