import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { OfferTilesBlock } from '@/src/@types/statamic'
import Button from '@/src/components/core/Button'
import Content from '@/src/components/elements/Content'
import Title from '@/src/components/elements/Title'
import Container from '@/src/components/layout/Container'

export default function Component({
  title,
  text,
  offer_tiles,
}: OfferTilesBlock) {
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevDisabled(!emblaApi.canScrollPrev())
    setNextDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  const prev = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const next = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <Container fullPageOnMobile>
      <div className="mx-5">
        <Title style={2}>{title}</Title>
        {text && <Content content={text} className="mt-4" />}
      </div>
      <div className="mt-6 overflow-hidden" ref={emblaRef}>
        <div className="flex select-none">
          {offer_tiles.map((offer) => (
            <div className="ml-5 min-w-0 flex-[0_0_80%] rounded-lg bg-blue-100 px-5 py-4 last:mr-5 md:flex-[0_0_40%]">
              <h3 className="text-2xl font-extrabold">{offer.title}</h3>
              {offer.subtitle && (
                <p className="mt-1 font-bold">{offer.subtitle}</p>
              )}
              <p className="mt-3">{offer.text}</p>
              {offer.link_link && (
                <Button
                  version="purple"
                  as={Link}
                  href={offer.link_link}
                  className="mt-4 inline-block"
                >
                  {offer.link_text || 'Mehr erfahren'}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
      {!(prevDisabled && nextDisabled) && (
        <div className="mr-5 mt-6 flex justify-end space-x-3">
          <Button
            size="icon-small"
            icon={<CaretLeft weight="bold" />}
            onClick={prev}
            version="purple-outlined"
            disabled={prevDisabled}
          />
          <Button
            size="icon-small"
            icon={<CaretRight weight="bold" />}
            onClick={next}
            version="purple-outlined"
            disabled={nextDisabled}
          />
        </div>
      )}
    </Container>
  )
}
